<template>
  <div ref="mainbox" class="mainbox">
    <!-- Intro -->
    <section class="section-main section-intro" id="main-intro" ref="mainIntro">
      <div class="section-main-inner">
        <div class="intro-text">
          <div
            class="txt-intro txt-intro01 slice-text"
            data-slice="By adding value to the experience"
          >
            <!-- By adding value to the experience -->
            <span class="_char">B</span>
            <span class="_char">y</span>
            <span class="_char">&nbsp;</span>
            <span class="_char">a</span>
            <span class="_char">d</span>
            <span class="_char">d</span>
            <span class="_char">i</span>
            <span class="_char">n</span>
            <span class="_char">g</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">v</span>
            <span class="_char">a</span>
            <span class="_char">l</span>
            <span class="_char">u</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">t</span>
            <span class="_char">o</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">t</span>
            <span class="_char">h</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">e</span>
            <span class="_char">x</span>
            <span class="_char">p</span>
            <span class="_char">e</span>
            <span class="_char">r</span>
            <span class="_char">i</span>
            <span class="_char">e</span>
            <span class="_char">n</span>
            <span class="_char">c</span>
            <span class="_char">e</span>
          </div>
          <div class="txt-intro txt-intro02 slice-text" data-slice="We make greatness">
            <!-- We make greatness -->
            <span class="_char">W</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">m</span>
            <span class="_char">a</span>
            <span class="_char">k</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">g</span>
            <span class="_char">r</span>
            <span class="_char">e</span>
            <span class="_char">a</span>
            <span class="_char">t</span>
            <span class="_char">n</span>
            <span class="_char">e</span>
            <span class="_char">s</span>
            <span class="_char">s</span>
          </div>
        </div>
        <div class="intro-bg"></div>
      </div>
    </section>
    <!-- section 01 -->
    <section class="section-main section-slogan">
      <div class="section-main-inner">
        <div class="slogan-text">
          <div
            class="txt-slogan txt-slogan01 slice-text"
            data-slice="By adding value to the experience"
          >
            <!-- By adding value to the experience -->
            <span class="_char">B</span>
            <span class="_char">y</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">a</span>
            <span class="_char">d</span>
            <span class="_char">d</span>
            <span class="_char">i</span>
            <span class="_char">n</span>
            <span class="_char">g</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">v</span>
            <span class="_char">a</span>
            <span class="_char">l</span>
            <span class="_char">u</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">t</span>
            <span class="_char">o</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">t</span>
            <span class="_char">h</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">e</span>
            <span class="_char">x</span>
            <span class="_char">p</span>
            <span class="_char">e</span>
            <span class="_char">r</span>
            <span class="_char">i</span>
            <span class="_char">e</span>
            <span class="_char">n</span>
            <span class="_char">c</span>
            <span class="_char">e</span>
          </div>
          <div class="txt-slogan txt-slogan02 slice-text" data-slice="We make greatness">
            <span class="_char">W</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">m</span>
            <span class="_char">a</span>
            <span class="_char">k</span>
            <span class="_char">e</span>
            <span class="_char space">&nbsp;</span>
            <span class="_char">g</span>
            <span class="_char">r</span>
            <span class="_char">e</span>
            <span class="_char">a</span>
            <span class="_char">t</span>
            <span class="_char">n</span>
            <span class="_char">e</span>
            <span class="_char">s</span>
            <span class="_char">s</span>
          </div>
        </div>

        <div class="scroll-down">
          <i class="ico-arr"></i><span class="txt">Scroll to Explore</span>
        </div>
      </div>
    </section>

    <!-- section 02 Work Items 진입 -->
    <section class="section-main work-prev" ref="workPrev" v-if="workListData.length > 2">
      <div class="work-prev-inner">
        <div class="work-thumbnail">
          <ul>
            <li>
              <div class="item">
                <img
                  :src="
                    imgUrl(
                      workListData[(itemCount + 5) % 6].pcSmallImagePhysicalName ||
                        workListData[(itemCount + 5) % 6].pcBigImagePhysicalName
                        ? workListData[(itemCount + 5) % 6].pcSmallImagePhysicalName ||
                            workListData[(itemCount + 5) % 6].pcBigImagePhysicalName
                        : require('@/assets/images/@temp/main-work-img-02.png')
                    )
                  "
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="item">
                <img
                  :src="
                    imgUrl(
                      workListData[itemCount].pcSmallImagePhysicalName ||
                        workListData[itemCount].pcBigImagePhysicalName
                        ? workListData[itemCount].pcSmallImagePhysicalName ||
                            workListData[itemCount].pcBigImagePhysicalName
                        : require('@/assets/images/@temp/main-work-img-02.png')
                    )
                  "
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="item">
                <img
                  :src="
                    imgUrl(
                      workListData[(itemCount + 1) % 6].pcSmallImagePhysicalName ||
                        workListData[(itemCount + 1) % 6].pcBigImagePhysicalName
                        ? workListData[(itemCount + 1) % 6].pcSmallImagePhysicalName ||
                            workListData[(itemCount + 1) % 6].pcBigImagePhysicalName
                        : require('@/assets/images/@temp/main-work-img-02.png')
                    )
                  "
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- section 02 Work -->
    <section
      class="section-main section-work"
      data-link="work"
      ref="secWork"
      v-if="workListData.length > 0"
    >
      <div class="section-work-innerbox">
        <div class="section-work-inner">
          <h2 class="sec-tit">GREAT PERFORMANCE</h2>
          <div class="work-wrap sec-cont">
            <section class="work-list" ref="workList">
              <!-- <div class="prev" @click="workNext">
                <img :src="require('@/assets/images/right-arrow.svg')" alt="" />
              </div>
              <div class="next" @click="workPrev">
                <img :src="require('@/assets/images/right-arrow.svg')" alt="" />
              </div> -->
              <ul>
                <li class="first">
                  <!-- 링크 없음 div 태그 사용 -->
                  <div class="item item-work">
                    <div class="img-area">
                      <img
                        :src="
                          imgUrl(
                            workListData[workListData.length - 1].pcSmallImagePhysicalName ||
                              workListData[workListData.length - 1].pcBigImagePhysicalName
                              ? workListData[workListData.length - 1].pcSmallImagePhysicalName ||
                                  workListData[workListData.length - 1].pcBigImagePhysicalName
                              : require('@/assets/images/@temp/main-work-img-02.png')
                          )
                        "
                        alt=""
                      />
                      <!-- <img :src="require('@/assets/images/@temp/main-work-img-01.png')" alt="" /> -->
                    </div>
                    <div class="cont-area">
                      <div
                        class="itm-cont itm-tag"
                        v-show="
                          workListData[workListData.length - 1].prize &&
                            workListData[workListData.length - 1].prize !== ''
                        "
                      >
                        {{ workListData[workListData.length - 1].prize }}
                      </div>
                      <div class="itm-cont itm-tit">
                        {{ workListData[workListData.length - 1].projectServiceName }}
                      </div>
                      <div class="itm-cont itm-date">
                        {{ workListData[workListData.length - 1].releaseDate }}
                      </div>
                    </div>
                  </div>
                </li>

                <li
                  v-for="(item, index) in workListData"
                  :key="item.id"
                  :class="{active: index === 0}"
                >
                  <router-link class="item item-work" :to="`/works/${item.id}`">
                    <div class="img-area">
                      <img
                        :src="
                          imgUrl(
                            item.pcSmallImagePhysicalName || item.pcBigImagePhysicalName
                              ? item.pcSmallImagePhysicalName || item.pcBigImagePhysicalName
                              : require('@/assets/images/@temp/main-work-img-02.png')
                          )
                        "
                        alt=""
                      />
                      <!-- <img :src="require('@/assets/images/@temp/main-work-img-02.png')" alt="" /> -->
                    </div>
                    <div class="cont-area">
                      <div class="itm-cont itm-tag" v-show="item.prize && item.prize !== ''">
                        {{ item.prize }}
                      </div>
                      <div class="itm-cont itm-tit">{{ item.projectServiceName }}</div>
                      <div class="itm-cont itm-date">{{ item.releaseDate }}</div>
                    </div>
                  </router-link>
                </li>
                <!-- <li class="active">
                  <a href="#" class="item item-work">
                    <div class="img-area">
                      <img :src="require('@/assets/images/@temp/main-work-img-02.png')" alt="" />
                    </div>
                    <div class="cont-area">
                      <div class="itm-cont itm-tag">Award</div>
                      <div class="itm-cont itm-tit">S2W</div>
                      <div class="itm-cont itm-date">2024</div>
                    </div>
                  </a>
                </li> -->
                <li class="last">
                  <div class="item item-work">
                    <div class="img-area">
                      <img
                        :src="
                          imgUrl(
                            workListData[0].pcSmallImagePhysicalName ||
                              workListData[0].pcBigImagePhysicalName
                              ? workListData[0].pcSmallImagePhysicalName ||
                                  workListData[0].pcBigImagePhysicalName
                              : require('@/assets/images/@temp/main-work-img-02.png')
                          )
                        "
                        alt=""
                      />
                    </div>
                    <div class="cont-area">
                      <div
                        class="itm-cont itm-tag"
                        v-show="workListData[0].prize && workListData[0].prize !== ''"
                      >
                        {{ workListData[0].prize }}
                      </div>
                      <div class="itm-cont itm-tit">
                        {{ workListData[0].projectServiceName }}
                      </div>
                      <div class="itm-cont itm-date">
                        {{ workListData[0].releaseDate }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div class="section-work-trigger"></div>
    </section>

    <!-- section03 Business -->
    <section class="section-main section-business">
      <div class="section-business-box">
        <div class="section-main-inner sec-cont">
          <div class="bg">
            <video id="video" muted autoplay loop>
              <source src="../assets/images/emotion_main_video.webm" type="video/webm" />
            </video>
          </div>
          <div class="cont business-cont">
            <section class="sec sec-text">
              <div class="text-wrap">
                <div class="txt-main">
                  <div class="_line" data-slice="We are digital experts">
                    We are digital experts
                  </div>
                  <div class="_line" data-slice="who will make">
                    who will make
                  </div>
                  <div class="_line" data-slice="your business greater.">
                    your business greater.
                  </div>
                </div>
                <div class="txt-sub">
                  <div>With e · motion, you can do anything</div>
                  <div>and become anyone.</div>
                </div>
              </div>
            </section>
            <section class="sec sec-list">
              <div class="list-wrap">
                <ul>
                  <li class="_active">UX / CX Consulting</li>
                  <li>AI Digital Solution</li>
                  <li>Data Analysis and Visualization</li>
                  <li>UX Research & Strategy</li>
                  <li>Experience Design</li>
                  <li>UI / GUI Design</li>
                  <li>Interaction Design</li>
                  <li>2D / 3D Motiongraphic</li>
                  <li>Brand Identity Design</li>
                  <li>Total Management</li>
                  <li>Creative idea&Development</li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <!-- section 04 Magazine -->
    <section class="section-main section-magazine" data-link="magazine">
      <div class="section-main-inner">
        <h2 class="sec-tit">RADICAL THINKING. <span class="color2">MAGAZINE</span></h2>
        <div class="magazine-wrap sec-cont">
          <ul class="item-list">
            <li class="item item-large" v-for="(item, index) in magazineListData" :key="index">
              <router-link
                :to="`/magazine/${item.id}/?list=${totalLength - index}`"
                class="article"
              >
                <div class="img-area">
                  <img :src="imgUrl(item.pcKeyPicturePhysicalName)" :alt="item.magazineTitle" />
                </div>
                <div class="cont-area">
                  <ul>
                    <li class="art-date">{{ item.reportingDate | dateFormat }}</li>
                    <li class="art-tit">{{ item.magazineTitle }}</li>
                    <li class="art-desc">{{ item.magazineDesc }}</li>
                  </ul>
                </div>
                <div class="hover-area">
                  <div class="hover-txt hover-txt1">Read More Read More Read More</div>
                  <div class="hover-txt hover-txt2">Read More Read More Read More</div>
                </div>
                <!-- <span class="num">
                  {{ totalLength - index }}
                </span> -->
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import {TweenMax, Power0, Power1, Power3, Expo, Back, TimelineMax} from "gsap";
  import {apiWorksList, apiMagazineList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  import bus from "@/utils/bus";
  // import LottieMuted from "@/components/lottie/muted.vue";

  export default {
    name: "home",
    // components: {LottieMuted},
    data() {
      return {
        windowTop: window.top.scrollY,
        mainSloganTl: null,
        isMuted: true,
        scene0: null,
        scene: null,
        scene2: null,
        scene3: null,
        scene4: null,
        prevItemsST: null,
        swiperOptions: {
          autoplay: true,
          loop: true,
          centeredSlides: true,
          slidesPerView: "auto"
        },
        itemCount: 0,
        isMoving: false,
        workTimeout: null,
        sectionName: "",
        swiperData: [
          {
            title: "UX / CX Consulting",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/00_UX-CX.png")
          },
          {
            title: "UX Research & Strategy",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/01_Research-Strategy.png")
          },
          {
            title: "Experience Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/02_Experience.png")
          },
          {
            title: "UI / GUI Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/03_UI-GUI.png")
          },

          {
            title: "Brand Identity Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/04_Brand-Identity.png")
          },

          {
            title: "Interaction Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/05_Interaction-Design.png")
          },
          {
            title: "2D/3D Motiongraphic",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/06_Motiongraphic.png")
          },
          {
            title: "Creative idea",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/07_Creative-Idea.png")
          },
          {
            title: "Creative Development",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/08_Creative-Development.png")
          },
          {
            title: "Digital Transformation",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/09_Digtal-Transformation.png")
          },
          {
            title: "SNS Marketing",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/10_SNS-Marketing.png")
          },
          {
            title: "Total Management",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/11_Total-Management.png")
          }
        ],
        keyword: "",
        workListData: [],
        magazineListData: [],
        totalLength: 0
      };
    },
    props: ["smoothScrollBar"],
    mixins: [commonMethods],
    created() {
      this.workListfetchData();
      this.magazineFetchData();

      // document.querySelector("#vid").play();
    },
    activated() {
      this.init();
    },
    mounted() {
      this.init();
      if (this.scene0 !== null) {
        this.$scrollmagic.addScene(this.scene0);
      }
      if (this.scene !== null) {
        this.$scrollmagic.addScene(this.scene);
      }
      if (this.scene2 !== null) {
        this.$scrollmagic.addScene(this.scene2);
      }
      if (this.scene3 !== null) {
        this.$scrollmagic.addScene(this.scene3);
      }
      if (this.scene4 !== null) {
        this.$scrollmagic.addScene(this.scene4);
      }
      // const secIntro = document.querySelector(".section-intro");
      // const introTexts = secIntro.querySelectorAll(".txt-intro");
      // const intro01 = secIntro.querySelector(".secIntro01");
      // const intro02 = secIntro.querySelector(".secIntro02");
      // const secSlogan = document.querySelector(".section-slogan");
      // const sloganTexts = secSlogan.querySelectorAll(".txt-slogan");
      // const slogan01 = sloganTexts[0];
      // const slogan02 = sloganTexts[1];

      // ① 텍스트 슬라이스
      // [intro01, intro02, slogan01, slogan02].forEach(line => {
      //   this.sliceText(line);
      // });

      // ② 텍스트 애니메이션
      setTimeout(this.mainIntro, 150);

      // smooth scrollbar Event
      window.addEventListener("scroll", this.onScroll);
      window.addEventListener("resize", () => {
        bus.$emit("winScrollTo", 0, 0);
        this.scene?.destroy(true);
        this.scene0?.destroy(true);
        this.scene2?.destroy(true);
        this.scene3?.destroy(true);
        this.scene4?.destroy(true);
        setTimeout(() => {
          this.mainSlogan();
          this.setWorkItemsSize();
          this.workListMove();
          this.prevItemsCreateST(); // work 썸네일 진입
        }, 500);
      });
    },
    beforeDestroy() {
      document.querySelector(".scroll-content").style.transform = "translateY(0)";
      window.clearTimeout(this.workTimeout);
      this.scene?.destroy(true);
      this.scene0?.destroy(true);
      this.scene2?.destroy(true);
      this.scene3?.destroy(true);
      this.scene4?.destroy(true);
      if (this.mainSloganTl !== null) {
        this.mainSloganTl = null;
      }
      window.removeEventListener("scroll", () => {
        this.introScroll();
        this.workSectionScroll();
        this.workListMove();
        this.onScroll();
      });
      window.removeEventListener("resize", () => {
        this.mainSlogan();
        this.setWorkItemsSize();
        this.workListMove();
        this.prevItemsCreateST();
      });
      const workLink = document.querySelector(".main-floating-link.link-work");
      const magazineLink = document.querySelector(".main-floating-link.link-magazine");
      setTimeout(() => {
        TweenMax.to(magazineLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });

        TweenMax.to(workLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });
      }, 200);
    },
    destroyed() {
      document.querySelector(".scroll-content").style.transform = "translateY(0)";
      window.clearTimeout(this.workTimeout);
      this.scene?.destroy(true);
      this.scene0?.destroy(true);
      this.scene2?.destroy(true);
      this.scene3?.destroy(true);
      this.scene4?.destroy(true);
      window.removeEventListener("scroll", () => {
        this.introScroll();
        this.workSectionScroll();
        this.workListMove();
        this.onScroll();
      });
      window.removeEventListener("resize", () => {
        this.mainSlogan();
        this.setWorkItemsSize();
        this.workListMove();
        this.prevItemsCreateST();
      });
      const workLink = document.querySelector(".main-floating-link.link-work");
      const magazineLink = document.querySelector(".main-floating-link.link-magazine");
      setTimeout(() => {
        TweenMax.to(magazineLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });

        TweenMax.to(workLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });
      }, 200);
    },
    methods: {
      onScroll() {
        this.windowTop = window.top.scrollY;
        // console.log(window.top.scrollY);
        setTimeout(() => {
          if (document.querySelector(".section-magazine") !== null) {
            const workLink = document.querySelector(".main-floating-link.link-work");
            const magazineLink = document.querySelector(".main-floating-link.link-magazine");

            if (window.top.scrollY < 50) {
              if (this.sectionName !== "home") {
                TweenMax.to(magazineLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });

                TweenMax.to(workLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });
              }
              this.sectionName = "home";
            } else if (
              window.top.scrollY <
              document.querySelector(".section-business").offsetTop - window.innerHeight
            ) {
              if (this.sectionName !== "works") {
                TweenMax.to(magazineLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });
                TweenMax.to(workLink, 0.3, {
                  autoAlpha: 1,
                  y: -100,
                  delay: 0.2,
                  ease: Back.easeOut
                });
              }
              this.sectionName = "works";
            } else if (
              window.top.scrollY <
              document.querySelector(".section-magazine").offsetTop - window.innerHeight
            ) {
              if (this.sectionName !== "business") {
                TweenMax.to(magazineLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });
                TweenMax.to(workLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });
              }
              this.sectionName = "business";
            } else if (
              window.top.scrollY >
                document.querySelector(".section-magazine").offsetTop - window.innerHeight &&
              window.top.scrollY < document.querySelector(".section-magazine").offsetTop
            ) {
              if (this.sectionName !== "magazine") {
                TweenMax.to(workLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });
                TweenMax.to(magazineLink, 0.3, {
                  autoAlpha: 1,
                  y: -100,
                  delay: 0.2,
                  ease: Back.easeOut
                });
              }
              this.sectionName = "magazine";
            } else {
              if (this.sectionName !== "") {
                TweenMax.to(magazineLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });

                TweenMax.to(workLink, 0.2, {
                  autoAlpha: 0,
                  y: 0
                });
              }
              this.sectionName = "";
            }
            // console.log(this.sectionName);
          }
        }, 100);
      },
      init() {
        this.scene0?.destroy(true);
        this.scene?.destroy(true);
        this.scene2?.destroy(true);
        this.scene3?.destroy(true);
        this.scene4?.destroy(true);
        // const keyVisual = document.querySelector(".mainbox");
        // const tween = TweenMax.to(keyVisual.querySelector(".section-intro"), 0.1, {
        //   y: keyVisual.querySelector(".section-intro").clientHeight
        //   // ease: Power0.easeInOut
        // });
        // this.scene = this.$scrollmagic
        //   .scene({
        //     triggerElement: keyVisual,
        //     triggerHook: 0,
        //     duration: keyVisual.clientHeight * 2
        //   })
        //   .setTween([tween]);
        // this.$scrollmagic.addScene(this.scene);
        const workLink = document.querySelector(".main-floating-link.link-work");
        const magazineLink = document.querySelector(".main-floating-link.link-magazine");
        TweenMax.to(workLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });
        TweenMax.to(magazineLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });
      },
      sliceText(elem) {
        if (!elem) return;

        const text = elem.dataset.slice || "";
        elem.innerHTML = [...text]
          .map(char => `<span class="_char">${char === " " ? "&nbsp;" : char}</span>`)
          .join("");
      },
      mainIntro() {
        const introBg = document.querySelector(".section-intro .intro-bg");
        const secIntro = document.querySelector(".section-intro");
        const introTexts = secIntro.querySelectorAll(".txt-intro");
        const intro01 = introTexts[0];
        const intro02 = introTexts[1];
        const intro01Char = intro01.querySelectorAll("._char");
        const intro02Char = intro02.querySelectorAll("._char");
        const mainHeader = document.querySelector(".new-header");
        const mainFooter = document.querySelector(".new-footer");
        const mainFloating = document.querySelector(".floating-menu");
        const mainIntro = this.$refs.mainIntro;

        TweenMax.to(document.querySelector(".scroll-content"), 0, {
          transform: "translateY(0) !important"
        });

        const mainIntroTl = new TimelineMax({
          defaults: {ease: "none"}
        });

        // const tween0 = TweenMax.to(document.querySelector(".section-intro"), 0.1, {
        //   ease: Power0.easeInOut,
        //   // top: () => parseInt(document.querySelector(".scroll-content").style.transform.split(",")[1]) * -1,
        //   onUpdate: () => {
        //     TweenMax.to(document.querySelector(".section-intro"), 0.3, {
        //       ease: Power0.easeInOut,
        //       top: () => parseInt(document.querySelector(".scroll-content").style.transform.split(",")[1]) * -1
        //     });
        //     console.log( document.querySelector(".scroll-content").style.transform, parseInt(document.querySelector(".scroll-content").style.transform.split(",")[1]) * -1 );
        //   }
        // });

        // this.scene0 = this.$scrollmagic
        //   .scene({
        //     triggerElement: document.querySelector(".section-intro"),
        //     // offset: window.top.scrollY - (secWork.offsetTop - (window.innerHeight - secWorkCont.offsetHeight)),
        //     triggerHook: 0,
        //     duration: document.querySelector(".scroll-content").clientHeight
        //   })
        //   // .addIndicators()
        //   .setTween([tween0]);
        // this.$scrollmagic.addScene(this.scene0);

        let vueThis = this;
        const workPrevItem = document.querySelectorAll(".work-thumbnail li");
        TweenMax.set(workPrevItem, {autoAlpha: 0});

        mainIntroTl
          // intro slogan 01
          .set(mainHeader, {autoAlpha: 0})
          .set(mainFooter, {autoAlpha: 0, display: "none"})
          .set(mainFloating, {autoAlpha: 0})
          .set(intro01, {autoAlpha: 1})
          .staggerFrom(
            intro01Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.024
          )
          .staggerTo(
            intro01Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 41) - 20,
              y: -5,
              ease: Back
            },
            0.015
          )
          .staggerTo(
            intro01Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0
              // ease: Back.easeOut
            },
            0.01
          )
          // intro slogan 02
          .to(intro02, 0, {autoAlpha: 1}, "-=.5")
          .staggerFrom(
            intro02Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            intro02Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 41) - 20,
              y: -5,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            intro02Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0,
              scale: 1.2
            },
            0.025
          )
          .to(".mainbox", 0, {
            height: "auto",
            onComplete: () => {
              // 메인 슬로건 시작
              vueThis.mainSlogan();
              // work thumbnail 등장
              vueThis.workThumbnailShow();
              vueThis.$refs.mainbox.classList.add("active");
              window.setTimeout(vueThis.mainWork, 200);
              document.querySelector(".scroll-content").style.transform = "translateY(0)";
              window.addEventListener("scroll", () => {
                vueThis.workSectionScroll();
                vueThis.workListMove();
              });
            }
          })
          .to(mainIntro, 0.3, {autoAlpha: "0"})
          .fromTo(introBg, 1, {scale: 0}, {scale: 200, ease: Expo.inOut}, "<")
          .to(mainFooter, 0.5, {autoAlpha: 1, display: "block"})
          .to(mainFloating, 0.5, {autoAlpha: 1}, "<")
          .to(mainHeader, 0.5, {autoAlpha: 1}, "<");
        // }
      },
      mainSlogan() {
        this.scene2?.destroy(true);
        this.scene3?.destroy(true);

        const secSlogan = document.querySelector(".section-slogan");
        const sloganTexts = secSlogan.querySelectorAll(".txt-slogan");
        const slogan01 = sloganTexts[0];
        const slogan02 = sloganTexts[1];
        const slogan01Char = slogan01.querySelectorAll("._char");
        const slogan02Char = slogan02.querySelectorAll("._char");
        if (this.mainSloganTl === null) {
          this.mainSloganTl = new TimelineMax({
            defaults: {ease: "none"},
            repeat: -1
          });
        } else {
          // console.log(this.mainSloganTl);
          this.mainSloganTl.invalidate();
          setTimeout(() => {
            this.mainSloganTl.restart();
          }, 1000);
        }
        // console.log(sloganTexts, slogan01, slogan01Char);

        this.mainSloganTl
          // slogan 01
          .to(slogan01, 0, {autoAlpha: 1})
          .staggerFrom(
            slogan01Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.024
          )
          .staggerTo(
            slogan01Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 41) - 20,
              y: -5,
              // yoyo: true,
              // repeat: 1,
              ease: Back
            },
            0.018
          )
          .staggerTo(
            slogan01Char,
            0.4,
            {
              rotation: 0,
              y: 0,
              ease: Back
            },
            0.02
          )
          .staggerTo(
            slogan01Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0,
              scale: 1.2,
              ease: Back.easeOut
            },
            0.01
          )
          // slogan 02
          .to(slogan02, 0, {autoAlpha: 1}, "-=.5")
          .staggerFrom(
            slogan02Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            slogan02Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 21) - 10,
              y: -5,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            slogan02Char,
            0.4,
            {
              rotation: 0,
              y: 0,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            slogan02Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0,
              scale: 1.2
            },
            0.02
          );
        const secWork = document.querySelector(".section-work");
        // const secWorkCont = secWork.querySelector(".section-work-inner");
        const workListWrap = secWork.querySelector(".work-list");
        const workList = workListWrap.querySelector("ul");
        const workItem = workList.querySelectorAll("li");
        const itemNum = workItem.length;
        const moveYPerItem = parseInt(secWork.clientHeight / itemNum);
        secWork.style.paddingBottom = `${moveYPerItem * (itemNum - 3)}px`;
        secWork.style.marginBottom = `${window.innerHeight}px`;
        // let vueThis = this;

        // console.log(secWork.clientHeight / itemNum);

        const tween2 = TweenMax.to(secWork.querySelector(".section-work-inner"), 1, {
          ease: Power0.easeInOut,
          top: () =>
            this.windowTop +
            document.querySelector(".section-work").offsetHeight -
            window.innerHeight +
            10
          // onUpdate: () => {
          //   // console.log(
          //   //   this.windowTop,
          //   //   document.querySelector(".section-work").offsetHeight,
          //   //   this.windowTop + (document.querySelector(".section-work").offsetHeight - ((window.innerHeight  - workItem[1].offsetHeight) * 0.5)),
          //   //   document.querySelector(".section-work").offsetTop
          //   // );
          // }
        });

        this.scene2 = this.$scrollmagic
          .scene({
            triggerElement: document.querySelector(".section-work"),
            // offset: window.top.scrollY - (secWork.offsetTop - (window.innerHeight - secWorkCont.offsetHeight)),
            triggerHook: 0,
            duration: document.querySelector(".section-work").clientHeight
          })
          // .addIndicators()
          .setTween([tween2]);
        this.$scrollmagic.addScene(this.scene2);

        if (document.querySelector(".business-cont .list-wrap") !== null) {
          const secBiz = document.querySelector(".section-business");

          const busiTextMain = document.querySelector(".business-cont .text-wrap .txt-main");
          const busiTextSub = document.querySelector(".business-cont .text-wrap .txt-sub");
          const busiList = document.querySelector(".business-cont .sec-list");

          secBiz.style.marginBottom = `${window.innerHeight * 2}px`;
          document.querySelector(".section-business .list-wrap").style.top = `${window.innerHeight /
            2}px`;
          // const secBuzIn = document.querySelector(".section-business .sec-cont");

          const tween3 = TweenMax.to(document.querySelector(".section-business-box"), 2, {
            ease: Power0.easeInOut,
            paddingTop: () => window.innerHeight * 3
            // onUpdate: () => {
            //   console.log(this.windowTop, document.querySelector(".section-business").offsetTop);
            // }
          });
          const tween30 = new TimelineMax();
          tween30
            .from(busiList, 0.1, {autoAlpha: 0, y: 0, ease: Back})
            .from(busiTextMain, 0.1, {autoAlpha: 1, y: 0, ease: Back}, "<")
            .from(busiTextSub, 0.1, {autoAlpha: 1, y: 0, delay: 0.05, ease: Back}, "<")
            .to(busiTextMain, 0.4, {autoAlpha: 0, y: 50, ease: Back})
            .to(
              busiTextSub,
              0.4,
              {
                autoAlpha: 0,
                y: 50,
                ease: Back,
                delay: 0.05
              },
              "<"
            )
            .to(busiList, 0.2, {
              autoAlpha: 1,
              y: 0
            })
            .to(document.querySelector(".section-business .list-wrap"), 1.0, {
              ease: Power0.easeOut,
              top: () =>
                window.innerHeight -
                document.querySelector(".business-cont .list-wrap").offsetHeight * 1,
              onUpdate: () => {
                const busiListWrap = document.querySelector(".business-cont .list-wrap");
                const busiListItems = busiListWrap.querySelectorAll("li");
                let topNumber =
                  (parseInt(busiListWrap.style.top, 10) - window.innerHeight / 2) * -1.5;
                let currentNumber = parseInt(
                  busiListWrap.offsetHeight / (busiListItems.length + 1)
                );
                let callNumber = parseInt(topNumber / currentNumber);

                busiListItems.forEach(item => {
                  if (item.classList.contains("_active")) {
                    TweenMax.to(item, 0.2, {
                      opacity: 0.1,
                      ease: Power0.easeInOut,
                      onComplete: () => {
                        item.classList.remove("_active");
                      }
                    });
                  } else {
                    TweenMax.to(item, 0.1, {opacity: 0.1, ease: Power0.easeInOut});
                  }
                });
                if (callNumber > 0 && callNumber < busiListItems.length) {
                  // console.log(callNumber);
                  busiListItems[callNumber].classList.add("_active");
                  TweenMax.to(busiListItems[callNumber], 0.2, {opacity: 1, ease: Power3.easeOut});
                } else if (callNumber <= 0) {
                  busiListItems[0].classList.add("_active");
                  TweenMax.to(busiListItems[0], 0.2, {opacity: 1, ease: Power3.easeOut});
                } else {
                  busiListItems[busiListItems.length - 1].classList.add("_active");
                  TweenMax.to(busiListItems[busiListItems.length - 1], 0.2, {
                    opacity: 1,
                    ease: Power3.easeOut
                  });
                }
              }
            });

          this.scene3 = this.$scrollmagic
            .scene({
              triggerElement: document.querySelector(".section-business"),
              // offset: window.top.scrollY - (secWork.offsetTop - (window.innerHeight - secWorkCont.offsetHeight)),
              triggerHook: 0,
              duration: document.querySelector(".section-business").offsetHeight * 1.5
            })
            // .addIndicators()
            .setTween([tween3, tween30]);
          // .on("end", () => {
          //   console.log("business scroll end");
          // });
          this.$scrollmagic.addScene(this.scene3);
        }
      },
      workThumbnailShow() {
        const workPrevItem = document.querySelectorAll(".work-thumbnail .item");

        const tl = new TimelineMax();
        tl.from(workPrevItem, 0, {autoAlpha: 0, y: 300})
          .to(workPrevItem, 0.4, {autoAlpha: 0, y: 300})
          .staggerTo(
            workPrevItem,
            0.3,
            {
              autoAlpha: 1,
              y: 0
            },
            0.1
          );
        return tl;
      },
      mainWork() {
        console.log("main-work");
        setTimeout(this.setWorkItemsSize, 100);
        // smooth scrollbar Event

        // Work List 진입 (Work prev.)
        this.prevItemsCreateST();
      },
      workSectionScroll() {
        if (document.querySelector(".section-work") !== null) {
          const secWork = document.querySelector(".section-work");
          const secWorkCont = secWork.querySelector(".section-work-inner");
          const workListWrap = secWork.querySelector(".work-list");
          // const workListTrigger = secWork.querySelector(".section-work-trigger");

          const workList = workListWrap.querySelector("ul");
          const workItem = workList.querySelectorAll("li");
          const itemNum = workItem.length;
          const moveYPerItem = 500;
          let secWorkContHeight = secWorkCont.offsetHeight;
          let startY, endY;
          const prevWork = document.querySelector(".work-prev");
          const prevItems = prevWork.querySelectorAll(".work-thumbnail ul li");

          startY = secWork.offsetTop - (window.innerHeight - secWorkContHeight);
          endY = startY + moveYPerItem * (itemNum - 3);

          // secWork Show/Hide
          if (scrollY >= startY && scrollY < endY + window.innerHeight * 2) {
            secWork.classList.add("_active");
            TweenMax.to(prevItems, 0, {autoAlpha: 0, delay: 0});
          } else {
            secWork.classList.remove("_active");
            TweenMax.to(prevItems, 0, {autoAlpha: 1, delay: 0});
          }

          // secWork fixed
          // if (scrollY >= startY && scrollY < endY) {
          //   // secWorkCont.style.transform = `translateY(${scrollY - startY}px)`;
          //   // TweenMax.to(secWorkCont, 0, {
          //   //   // ease: Power0.easeInOut,
          //   //   y: `${scrollY - startY}px`
          //   // });
          // }
        }
      },
      workListMove() {
        if (document.querySelector(".section-work") !== null) {
          const secWork = document.querySelector(".section-work");
          const secWorkCont = secWork.querySelector(".section-work-inner");
          const workListWrap = secWork.querySelector(".work-list");
          const workList = workListWrap.querySelector("ul");
          const workItem = workList.querySelectorAll("li");
          const itemNum = workItem.length;
          const moveYPerItem = parseInt(secWork.offsetHeight / (itemNum - 3));
          let listWidth = workList.scrollWidth;
          let itemWidth = workItem[1].offsetWidth;
          let itemSpace = (listWidth - itemWidth * itemNum) / (itemNum - 1);
          let secWorkContHeight = secWorkCont.offsetHeight;
          let scrollY = window.top.scrollY;
          let startY;
          let endY;

          startY = secWork.offsetTop - (window.innerHeight - secWorkContHeight);
          endY = startY + moveYPerItem * (itemNum - 3) + window.innerHeight;
          // console.log("scrollY", secWork.offsetHeight / (itemNum - 3), scrollY, startY, endY, this.isMoving);

          if (scrollY < startY || scrollY > endY) return;

          this.isMoving = true;
          const moveCount = Math.round((scrollY - startY) / (moveYPerItem - 100));
          // console.log("WorkmoveCount", moveCount, this.itemCount, itemNum - 3);
          if (moveCount > itemNum - 3) {
            this.itemCount = itemNum - 3;
          } else {
            this.itemCount = moveCount;
          }

          // console.log(moveCount);

          const workTween = new TimelineMax({
            onStart: this.workScrollStart
          });
          workTween.to(workList, 0.75, {
            x: () => -(itemWidth + itemSpace) * this.itemCount,
            ease: Power1.easeOut,
            onComplete: this.workScrollEnd
          });
        }
      },
      workScrollStart() {
        const secWork = document.querySelector(".section-work");
        const workListWrap = secWork.querySelector(".work-list");
        const workList = workListWrap.querySelector("ul");
        const workItem = workList.querySelectorAll("li");
        this.isMoving = true;
        // this.smoothScrollBar.setMomentum(0, 0); // 스크롤 잠시 멈춤
        // Scrollbar.setMomentum(0, 0);

        workItem.forEach(item => item.classList.remove("active"));
        workItem[this.itemCount + 1].classList.add("active");
      },
      workScrollEnd() {
        setTimeout(() => {
          this.isMoving = false;
        }, 200);
      },
      setWorkItemsSize() {
        const secWork = document.querySelector(".section-work");
        const workListWrap = secWork.querySelector(".work-list");
        const workList = workListWrap.querySelector("ul");
        const workItem = workList.querySelectorAll("li");
        const itemNum = workItem.length;
        let listWidth = workList.scrollWidth;
        let itemWidth = workItem[1].offsetWidth;
        let itemSpace = (listWidth - itemWidth * itemNum) / (itemNum - 1);
        let sideItemWidth = Math.round((window.innerWidth - 160 - itemSpace * 2 - itemWidth) / 2);

        workList.style.left = `-${itemWidth - sideItemWidth}px`;
      },
      prevItemsCreateST() {
        // console.log(this);
        this.scene?.destroy(true);
        const secSlogan = document.querySelector(".section-slogan");
        const secWork = document.querySelector(".section-work");
        const workListWrap = secWork.querySelector(".work-list");
        const workList = workListWrap.querySelector("ul");
        const workItem = workList.querySelectorAll("li");
        const itemNum = workItem.length;

        let listWidth = workList.scrollWidth;
        let itemWidth = workItem[1].offsetWidth;
        let itemSpace = (listWidth - itemWidth * itemNum) / (itemNum - 1);
        let sideItemWidth = (window.innerWidth - 160 - itemSpace * 2 - itemWidth) / 2; // 160 좌우 padding

        const prevWork = document.querySelector(".work-prev");
        const prevItems = prevWork.querySelectorAll(".work-thumbnail ul li");

        const t1 = TweenMax.fromTo(
          prevItems[0],
          0.3,
          {
            y: () => window.innerHeight - 90 - 40,
            height: 90,
            width: 137
          },
          {
            y: () => window.innerHeight * 1.5 - workItem[1].offsetHeight * 0.5 + 72,
            height: () => workItem[1].offsetHeight,
            width: sideItemWidth,
            marginLeft: 0,
            marginRight: 0
          }
        );

        const t2 = TweenMax.fromTo(
          prevItems[1],
          0.3,
          {
            y: () => window.innerHeight - 90 - 40,
            height: 90,
            width: 137
          },
          {
            y: () => window.innerHeight * 1.5 - workItem[1].offsetHeight * 0.5 + 72,
            height: () => workItem[1].offsetHeight,
            width: itemWidth,
            marginLeft: itemSpace,
            marginRight: itemSpace,
            delay: 0.05
          }
        );

        const t3 = TweenMax.fromTo(
          prevItems[2],
          0.3,
          {
            y: () => window.innerHeight - 90 - 40,
            height: 90,
            width: 137
          },
          {
            y: () => window.innerHeight * 1.5 - workItem[1].offsetHeight * 0.5 + 72,
            height: () => workItem[1].offsetHeight,
            width: sideItemWidth,
            marginLeft: 0,
            marginRight: 0,
            delay: 0.1
          }
        );
        // .set(prevItems, {autoAlpha: 0});

        // console.log( window.innerHeight, workItem[1].offsetHeight);

        this.scene = this.$scrollmagic
          .scene({
            offset: 0,
            triggerElement: secSlogan,
            triggerHook: 0,
            duration: window.innerHeight
          })
          .setTween([t1, t2, t3])
          .setPin(document.querySelector(".work-prev"))
          // .addIndicators()
          .on("start enter progress", () => {
            window.clearTimeout(this.workTimeout);
            // console.log("work scroll progress");
          })
          .on("end", () => {
            this.workListMove();
            this.mainMagazine();
            this.itemCount = 0;
            // console.log("work scroll end");
          });
        this.$scrollmagic.addScene(this.scene);

        // prevItemsEnterToList();
        // if (this.prevItemsST) prevItemsST.kill();
        // this.prevItemsST = ScrollTrigger.create({
        //   trigger: ".work-prev",
        //   start: "top top",
        //   end: () => `+=${window.innerHeight}`,
        //   scrub: true,
        //   animation: prevItemsEnterToList(),
        //   ease: "none",
        // });
      },
      mainBusiness() {
        console.log("mainBusiness");
      },
      mainMagazine() {
        this.scene4?.destroy(true);
        // console.log("mainMagazine");
        const secMagazine = document.querySelector(".section-magazine");
        const magList = secMagazine.querySelector(".item-list");
        const articles = magList.querySelectorAll(".article");

        const articleEnter = new TimelineMax();

        // 매거진 아이템 등장
        const t1 = articleEnter.staggerFromTo(
          articles,
          0.55,
          {
            autoAlpha: 0,
            yPercent: 15
          },
          {
            autoAlpha: 1,
            yPercent: 0,
            ease: Power1.easeInOut,
            onComplete: () => {
              articles.forEach(item => {
                item.classList.add("_active");
              });
            }
          },
          0.3
        );

        this.scene4 = this.$scrollmagic
          .scene({
            triggerElement: document.querySelector(".section-magazine"),
            triggerHook: 0.55,
            duration: 600
          })
          .setTween([t1]);
        this.$scrollmagic.addScene(this.scene4);
      },
      async workListfetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiWorksList({
            page: 0,
            size: 6,
            keyword: this.keyword
          });
          this.workListData = response.content;
        } catch (error) {
          console.error(error.response);
        }
      },
      async magazineFetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiMagazineList({
            page: 0,
            size: 5,
            keyword: this.keyword
          });
          this.magazineListData = response.content;
          this.totalLength = response.totalElements;
        } catch (error) {
          console.error(error.response);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .mainbox {
    overflow: hidden;
    // position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    &.active {
      position: relative;
      height: auto;
    }
  }

  // Section
  .section-main {
    font-family: "Montserrat", sans-serif;
    position: relative;

    .section-main-inner {
      position: relative;
    }

    .sec-tit {
      margin-bottom: 80px;
      color: #000;
      font-size: 24px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;

      .color2 {
        color: $red;
      }
    }
  }

  // Section Intro
  .section-intro {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;

    .section-main-inner {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .intro-bg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      transform: translate(-50%, 0);
      background-color: #fff;
      z-index: 2;
    }

    .intro-text {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 1;
      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        background-color: $red;
      }

      .txt-intro {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        transform: translate(-50%, -50%);
        color: #fff;
        font-family: "Montserrat", sans-serif;
        font-size: clamp(50px, 5vw, 90px);
        font-weight: 600;
        letter-spacing: -0.05em;
        white-space: nowrap;
        opacity: 0;
        ._char {
          &.space {
            min-width: 20px;
          }
        }
      }
    }
  }

  // Section Slogan
  .section-slogan {
    .section-main-inner {
      position: relative;
      width: 100vw;
      height: 100vh;
    }

    .slogan-text {
      .txt-slogan {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        transform: translate(-50%, -50%);
        color: $red;
        font-family: "Montserrat", sans-serif;
        font-size: clamp(50px, 5vw, 85px);
        // font-size: 90px;
        font-weight: 700;
        letter-spacing: -0.05em;
        white-space: nowrap;
        opacity: 0;
        ._char {
          &.space {
            min-width: 20px;
          }
        }
      }
    }

    .scroll-down {
      position: absolute;
      left: 80px;
      bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 12px;

      .ico-arr {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        background: url(../assets/images/common/ico-arrow-up.svg) no-repeat center/contain;
        // transform: translate3d(0, 0, 0) rotate(180deg);
        transition: 0.2s ease-in;
        animation: yoyoDown 0.3s infinite alternate;
      }
      .txt {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  @keyframes yoyoDown {
    0% {
      transform: translate3d(0, 5px, 0) rotate(180deg);
    }
    100% {
      transform: translate3d(0, -5px, 0) rotate(180deg);
    }
  }

  // Work List Intro
  .work-prev {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 200vh;
    pointer-events: none;
    z-index: 3;
    // visibility: hidden;

    .work-prev-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .work-thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        ul {
          display: flex;
          justify-content: center;
          // background-color: rgba(255, 0, 0, 0.1);
          li {
            position: relative;

            .item {
              position: relative;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              overflow: hidden;
              visibility: hidden;
              background: #232323;

              img {
                max-width: auto;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          li:nth-child(1) {
            .item img {
              object-position: right top;
            }
          }

          li:nth-child(2) {
            margin: 0 20px;
          }

          li:nth-child(3) {
            .item img {
              object-position: left top;
            }
          }
        }
      }
    }
  }

  // Section Work
  .section-work {
    position: relative;
    width: 100vw;
    height: 100vh;

    // .work-wrap {}
    &._active {
      .work-wrap {
        opacity: 1;
        transition: opacity 0s linear 0s;
      }
      // .section-main-inner {
      //   // background-color: pink;
      // }
    }
    .section-work-trigger {
      position: absolute;
      top: 0;
      height: 100vh;
    }
    .section-work-innerbox {
      position: relative;
    }
    .section-work-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      width: 100vw;
      height: 100vh;
      padding: 120px 80px;
      box-sizing: border-box;
    }
    .work-wrap {
      padding-bottom: 104px;
      opacity: 0;

      .next {
        position: absolute;
        top: 50%;
        left: 2rem;
        z-index: 10;
        transform: translateY(-50%);
        img {
          cursor: pointer;
          width: 4rem;
          transform: rotate(180deg);
        }
      }

      .prev {
        position: absolute;
        top: 50%;
        right: 2rem;
        z-index: 10;
        transform: translateY(-50%);
        img {
          cursor: pointer;
          width: 4rem;
        }
      }

      .work-list {
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        ul {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 4vw;

          li {
            flex-shrink: 0;
          }

          // .last {
          //   content: '';
          //   display: block;
          //   position: absolute;
          //   top: 0;
          //   right: 0;
          //   width: 523px;
          //   width: 100%;
          //   height: 100%;
          //   background-color: pink;
          //   border: 1px solid red;
          // }
        }

        .item-work {
          position: relative;
          border-radius: 10px;
          overflow: hidden;

          .img-area {
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            z-index: 1;
            // background: #232323;

            img {
              display: block;
              width: auto;
              height: calc((100vw - 160px) * 2 / 3 / 1.8);
              max-height: 60vh; // @@
              aspect-ratio: 3 / 2;
              object-fit: cover;
              transition: 0.4s ease-in-out;
            }
          }

          .cont-area {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            transition: 0.4s;
            opacity: 0;
            z-index: 2;

            .itm-cont {
              margin: 40px;
              color: #fff;
            }

            .itm-tag {
              position: absolute;
              top: 0;
              left: 0;
              display: inline-flex;
              padding: 10px 24px;
              border: 1px solid #ddd;
              border-radius: 17px;
              background-color: #fff;
              color: #000;
              font-size: 14px;
              font-weight: 500;
              box-sizing: border-box;
            }
            .itm-tit {
              font-family: "Noto Sans KR", sans-serif;
              font-size: 24px;
              font-weight: 500;
              white-space: nowrap;
            }
            .itm-date {
              margin: 40px;
              font-size: 14px;
            }
          }

          &:hover {
            .img-area {
              img {
                transform: scale(1.05);
              }
            }
          }
        }

        // work item active 처리
        li.active .item-work {
          .cont-area {
            opacity: 1;
          }
        }
      }
    }
  }

  // Section Business
  .section-business {
    position: relative;
    height: 200vh;

    .section-business-box {
      position: relative;
    }
    .section-main-inner {
      position: relative;
      width: 100vw;
      height: 100vh;
    }

    .bg {
      position: absolute;
      inset: 0;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
      }
      #video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .business-cont {
      position: relative;
      width: 100vw;
      height: 100vh;
      color: #fff;
      font-family: "Montserrat", sans-serif;

      * {
        position: relative;
        font-family: "Montserrat", sans-serif;
        // letter-spacing: -0.02em;
        line-height: 1.2;
      }

      .sec {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        // opacity: 0;
      }
      .text-wrap {
        .txt-main {
          position: relative;
          font-size: clamp(40px, 4.6vw, 82px);
          font-weight: 500;
          text-align: center;
          ._line {
            position: relative;
          }
        }

        .txt-sub {
          margin-top: 80px;
          color: #cccccc;
          font-size: 32px;
          font-size: clamp(20px, 1.9vw, 32px);
          font-weight: 400;
          text-align: center;
        }
      }

      // 비지니스 아이템 리스트
      .sec.sec-list {
        position: relative;
        width: 100vw;
        height: 100vh;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        opacity: 0;
      }
      .list-wrap {
        position: relative;

        ul {
          position: relative;
          display: flex;
          // flex-wrap: wrap;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          row-gap: 50px;
          padding: 0 80px;
          padding-bottom: 50vh;
          font-size: clamp(40px, 4.6vw, 82px);
          line-height: 1;
          li {
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0 0.3em;
            color: #fff;
            // font-size: clamp(20px, 4vw, 60px);
            font-weight: 500;
            line-height: 1;
            white-space: nowrap;
            opacity: 0.1;
            // transition: opacity 0.05s ease-out 0.25s;
            // &._active {
            //   opacity: 1;
            //   transition: opacity 0.25s ease-in-out;
            // }
          }
        }
      }
    }
  }

  // Section Magazine
  .section-magazine {
    padding: 120px 80px;

    .section-main-inner {
      max-width: 1760px;
    }

    .magazine-wrap {
      position: relative;
      width: 100%;
      // max-width: 1760px;
      // margin: 0 auto;
      .item-list {
        //ul
        display: grid;
        grid-template-columns: 7fr 5fr 5fr;
        grid-template-rows: 5fr 5fr;
        gap: 20px;
        max-width: 1760px;

        .item {
          grid-column: span 1;
          grid-row: span 1;
          width: calc((100vw - 200px) * 0.3);
          height: calc((100vw - 200px) * 0.3);
        }
        .item:nth-child(1) {
          grid-row: span 2;
          width: calc((100vw - 200px) * 0.4);
          height: auto;
          min-height: calc((100vw - 200px) * 0.4);
        }
      }
    }

    .article,
    a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
      border-radius: 10px;
      color: #fff;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $red;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: 2;
      }

      .img-area {
        width: 100%;
        height: 100%;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .cont-area {
        position: relative;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: clamp(16px, 1.25vw, 24px);
        z-index: 1;

        > ul {
          max-width: 100%;
          padding: 30px 20px;
          li {
            position: relative;
            opacity: 0;
            white-space: normal;
            word-break: break-all;
            line-height: 1.2;
            transition: all 0.4s ease-in;

            &.art-date {
              font-size: 0.62em;
            }
            &.art-tit {
              height: 68px;
              height: clamp(48px, 3.5vw, 68px);
              margin-top: 0.41em;
              font-family: "Noto Sans KR", sans-serif;
              font-size: 1em;
              line-height: 1.4;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              height: 2.8em;
              // -webkit-box-orient: vertical;
            }
            &.art-desc {
              height: 34px;
              height: clamp(23px, 1.75vw, 34px);
              margin-top: 1.2em;
              font-family: "Noto Sans KR", sans-serif;
              font-size: 0.58em;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              line-clamp: 2;
            }
          }
          li:nth-child(1) {
            transform: translate(0, -5px);
          }
          li:nth-child(2) {
            transform: translate(0, -15px);
          }
          li:nth-child(3) {
            transform: translate(0, -25px);
          }
        }
      }

      .hover-area {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition-duration: 0.3s;
        pointer-events: none;
        z-index: 3;
        .hover-txt {
          padding-left: 20px;
          font-size: 3.3vw;
          font-weight: 500;
          letter-spacing: -5%;
          white-space: nowrap;
          transition-duration: 0.3s;
          opacity: 0;
          z-index: 4;
        }
        .hover-txt1 {
          animation: marquee 15s linear infinite;
        }
        .hover-txt2 {
          position: absolute;
          animation: marquee2 15s linear infinite;
        }
      }

      // hover-area hover
      &:hover {
        .hover-area {
          opacity: 1;
          backdrop-filter: blur(5px);
          .hover-txt {
            opacity: 1;
          }
        }
        &::before {
          .hover-area {
            opacity: 1;
            .hover-txt {
              opacity: 1;
            }
          }
        }
        &::after {
          opacity: 0.8;
        }
      }

      // _active
      &._active {
        .cont-area {
          ul {
            li {
              opacity: 1;
              transition: all 0.4s ease-in;
              transform: translate(0, 0);
            }
            li:nth-child(1) {
              transition-delay: 0.05s;
            }
            li:nth-child(2) {
              transition-delay: 0.15s;
            }
            li:nth-child(3) {
              transition-delay: 0.2s;
            }
          }
        }
      } //_active
    }
  }

  @keyframes textEnter {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee2 {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  // 메인에서만 해당 스타일 적용!!!!! #container 안으로 넣었을 경우
  .fixed-wrap {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9;

    .main-floating-section-link {
      position: absolute;
      bottom: 100px;
      pointer-events: initial;
    }
  }
</style>
